// extracted by mini-css-extract-plugin
export var user = "index-module--user--3WWhO";
export var imgdiv = "index-module--imgdiv--2pYML";
export var avatar = "index-module--avatar--23rl0";
export var description = "index-module--description--31C3R";
export var username = "index-module--username--m7zbP";
export var excerpt = "index-module--excerpt--2XGQH";
export var themename = "index-module--themename--BPfgr";
export var button = "index-module--button--tcrJg";
export var button2 = "index-module--button2--26k5A";
export var button3 = "index-module--button3--1hhdr";